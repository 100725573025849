import React from 'react';
import Faq from '../Faq/Faq';
import { Trans } from 'gatsby-plugin-react-i18next';
import { FaqType } from '../../types/faq.types';

const FAQS: Array<FaqType> = [
  {
    question: <Trans>Можно ли заработать на беттинге?</Trans>,
    answer: (
      <>
        <p>
          <Trans>
            Беттинг — сложная сфера для получения стабильного дохода. Профессиональные игроки хорошо разбираются в профильных областях спорта, анализируют большой объём информации и используют вилочные ставки для получения гарантированного выигрыша.
          </Trans>
        </p>
        <p>
          <Trans>
            Вилки — это стратегия заработка на ставках, при которой игрок делает ставки на противоположные исходы и при любом исходе выигрывает за счет разницы в коэффициентах. Для реализации этой стратегии необходим инструмент позволяющий создавать и управлять множеством аккаунтов на сайтах букмекерских контор, такой как Octo Browser.
          </Trans>
        </p>
      </>
    ),
  },
  {
    question: <Trans>Как избежать рисков блокировки?</Trans>,
    answer: (
      <>
        <p>
          <Trans>
            Для заработка на онлайн-ставках вам необходимо несколько учетных записей, но букмекерские сайты внимательно анализируют действия каждого пользователя, данные его устройства, местоположение, а также информацию о подключении.
          </Trans>
        </p>
        <p>
          <Trans>
            Octo Browser легко обходит алгоритмы безопасности букмекерских сайтов, полностью подменяя параметры браузерного отпечатка для каждого профиля. Отследить связь аккаунтов между собой невозможно.
          </Trans>
        </p>
      </>
    ),
  },
  {
    question: (
      <Trans>Получение бонусов в букмекерских конторах</Trans>
    ),
    answer: (
      <>
        <p>
          <Trans>
            Бонусхантинг — это дополнительный способ заработка в беттинге, за счет получения приветственных бонусов или фрибетов, предназначенных для привлечения новых игроков. Но если вы уже были зарегистрированы – букмекерская контора не позволит воспользоваться этими бонусами.
          </Trans>
        </p>
        <p>
          <Trans>
            Octo Browser позволяет регистрировать новые аккаунты с одного устройства, не опасаясь риска блокировки. Для создания виртуального профиля используется отпечаток браузера с конфигурацией реального устройства. Даже для самых совершенных систем идентификации пользователей, такой аккаунт не отличим от любого нового игрока.
          </Trans>
        </p>
      </>
    ),
  }
];

type FaqHuntingProps = {
  title: JSX.Element;
};
const FaqHunting = ({ title }: FaqHuntingProps): React.ReactElement => {
  return <Faq isWhite faqs={FAQS} title={title} />;
};

export default FaqHunting;
