import React from 'react';
import { graphql } from 'gatsby';
import { Trans } from 'gatsby-plugin-react-i18next';
import Layout from '../../layouts';
import DownBlock from '../../components/DownBlock/DownBlock';
import AppRating from '../../components/AppRating';
import SolutionLayout from '../../components/Solutions/SolutionLayout/SolutionsLayout';
import headRuSrc from '../../assets/images/solutions/hunting/head_ru.png';
import headRuSrc2x from '../../assets/images/solutions/hunting/head_ru_2x.png';
import headSrc from '../../assets/images/solutions/hunting/head.png';
import headSrc2x from '../../assets/images/solutions/hunting/head_2x.png';
import Description1 from '../../assets/images/solutions/hunting/description_1.svg';
import Description2 from '../../assets/images/solutions/hunting/description_2.svg';
import Description3 from '../../assets/images/solutions/hunting/description_3.svg';
import Description4 from '../../assets/images/solutions/hunting/description_4.svg';
import block1RuSrc from '../../assets/images/solutions/hunting/block_1_ru.png';
import block1RuSrc2x from '../../assets/images/solutions/hunting/block_1_ru_2x.png';
import block1Src from '../../assets/images/solutions/hunting/block_1.png';
import block1Src2x from '../../assets/images/solutions/hunting/block_1_2x.png';
import block2Src from '../../assets/images/solutions/hunting/block_2.png';
import block2Src2x from '../../assets/images/solutions/hunting/block_2_2x.png';
import block3Src from '../../assets/images/solutions/hunting/block_3.png';
import block3Src2x from '../../assets/images/solutions/hunting/block_3_2x.png';
import block4Src from '../../assets/images/solutions/hunting/block_4.png';
import block4Src2x from '../../assets/images/solutions/hunting/block_4_2x.png';
import block5Src from '../../assets/images/solutions/hunting/block_5.png';
import block5Src2x from '../../assets/images/solutions/hunting/block_5_2x.png';
import ImageContainer from '../../components/ImageContainer/ImageContainer';
import BlockTitle from '../../components/BlockTitle/BlockTitle';
import FaqHunting from '../../components/FaqHunting/FaqHunting';
import { LanguageMap } from '../../const/language.const';

const HuntingPage = (): JSX.Element =>  {
  const head = {
    title: <Trans>Беттинг и бонусхантинг</Trans>,
    text: (
      <Trans>
        Octo Browser позволяет создать новую уникальную цифровую личность, готовую пройти любые проверки букмекеров и онлайн-казино. Глубокие настройки цифровых отпечатков позволяют зарабатывать с помощью мультиаккаунтинга, не опасаясь блокировки.
      </Trans>
    ),
    images: [
      {
        image: <ImageContainer src={headSrc} srcSet={{ _2x: headSrc2x }} />,
        locales: {
          exclude: [LanguageMap.ru.key],
        },
      },
      {
        image: <ImageContainer src={headRuSrc} srcSet={{ _2x: headRuSrc2x }} />,
        locales: {
          include: [LanguageMap.ru.key],
        },
      }
    ],
  };

  const descriptions = [
    {
      icon: <Description1 />,
      title: <Trans>Проверка анонимности</Trans>,
      text: (
        <Trans>
          Проверьте Octo Browser на утечку данных такими известными чекерами как Pixelscan, BrowserLeaks, CreepJS, Whoer и IP-API, и убедитесь в его надёжности.
        </Trans>
      ),
    },
    {
      icon: <Description2 />,
      title: <Trans>Продвинутый мультиаккаунтинг</Trans>,
      text: (
        <Trans>
          Управляйте большим количеством аккаунтов с максимальной защитой от блокировок и поддержкой всех популярных типов прокси.
        </Trans>
      ),
    },
    {
      icon: <Description3 />,
      title: <Trans>Профиль в один клик</Trans>,
      text: (
        <Trans>
          Используйте детальные настройки параметров или создавайте быстрые профили в один клик и сразу приступайте к работе.
        </Trans>
      ),
    },
    {
      icon: <Description4 />,
      title: <Trans>Сохранение сессии</Trans>,
      text: (
        <Trans>
          Зарабатывайте сами или с партнёрами, не опасаясь дополнительных запросов верификации личности при частой смене устройств и IP-адресов.
        </Trans>
      ),
    },
  ];

  const blocks = {
    items: [
      {
        images: [
          {
            image: (
              <ImageContainer src={block1RuSrc} srcSet={{ _2x: block1RuSrc2x }} />
            ),
            locales: {
              include: [LanguageMap.ru.key],
            },
          },
          {
            image: (
              <ImageContainer src={block1Src} srcSet={{ _2x: block1Src2x }} />
            ),
            locales: {
              exclude: [LanguageMap.ru.key],
            },
          },
        ],
        title: <Trans>Бонусхантинг</Trans>,
        text: (
          <Trans>
            Создавайте неограниченное количество новых аккаунтов, легко преодолевая проверки защитных алгоритмов беттинговых площадок.
          </Trans>
        ),
      },
      {
        images: [
          {
            image: (
              <ImageContainer src={block2Src} srcSet={{ _2x: block2Src2x }} />
            ),
          },
        ],
        title: <Trans>Автоматизация ставок</Trans>,
        text: (
          <Trans>
            Автоматизируйте выполнение множества ручных действий и реагируйте на изменение коэффициентов ставок мгновенно.
          </Trans>
        ),
      },
      {
        images: [
          {
            image: (
              <ImageContainer src={block3Src} srcSet={{ _2x: block3Src2x }} />
            ),
          },
        ],
        title: <Trans>Работа в команде</Trans>,
        text: (
          <Trans>
            Используйте гибкие настройки ролей и прав доступа к виртуальным профилям, для эффективной командной работы с любым количеством букмекеров.
          </Trans>
        ),
      },
      {
        images: [
          {
            image: (
              <ImageContainer src={block4Src} srcSet={{ _2x: block4Src2x }} />
            ),
          },
        ],
        title: <Trans>Распределение рисков</Trans>,
        text: (
          <Trans>
            Параметры виртуальных профилей не связаны между собой и не обмениваются данными, что исключает потерю всех аккаунтов при блокировке одного.
          </Trans>
        ),
      },
      {
        images: [
          {
            image: (
              <ImageContainer src={block5Src} srcSet={{ _2x: block5Src2x }} />
            ),
          },
        ],
        title: <Trans>Обход лимита ставок</Trans>,
        text: (
          <Trans>
            Решайте проблему лимитов ставок использованием нескольких аккаунтов. Не упускайте возможность заработать здесь и сейчас!
          </Trans>
        ),
      },
    ],
    title: (
      <BlockTitle
        highlightIsBlock
        highlightText={<Trans>в сфере беттинга</Trans>}
        title={<Trans>Возможности Octo Browser</Trans>}
        maxWidthTitle={800}
      />
    ),
  };
  const faq = (
    <FaqHunting
      title={
        <BlockTitle
          highlightIsBlock
          highlightText={<Trans>и начать зарабатывать на ставках</Trans>}
          title={<Trans>Как избежать рисков</Trans>}
          maxWidthTitle={730}
        />
      }
    />
  );
  return (
    <Layout>
      <AppRating />
      <SolutionLayout
        head={head}
        descriptions={descriptions}
        blocks={blocks}
        faq={faq}
      />
      <DownBlock />
    </Layout>
  );
};

export default HuntingPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["huntingPage", "translation"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
